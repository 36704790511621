import React from 'react';
import Layout from 'components/Layout';
import LayoutContainer from 'components/LayoutContainer';
import Markdown from 'components/Markdown';
import content from 'content/terms-of-service.md';

const TermsOfService = () => (
  <Layout title="Terms of Service">
    <LayoutContainer as="main" style={{ marginTop: '150px' }} layout="narrow">
      <Markdown content={content} />
    </LayoutContainer>
  </Layout>
);

export default TermsOfService;
